import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Wrapper, Tariffs, More, Inner, PriceInfo } from './pricing.styled';
import { Container } from '../../../components/web/container/container';
import { TariffPremium } from '../../../components/web/tariff/premium/tariff-premium';
import { PricingSwitchPayment } from './switch-payment/pricing-switch-payment';
import { Button } from '../../../components/app/form/button/button';
import { IconNext } from '../../../components/app/icons/next';
import { routes } from '../../../config/routes';
import { TariffExclusive } from '../../../components/web/tariff/exclusive/tariff-exclusive';

export const Pricing: React.FC = () => {
    const { t } = useTranslation();
    const [period, setPeriod] = useState<'yearly' | 'monthly'>('yearly');

    return (
        <Wrapper id="pricing">
            <Container>
                <Inner>
                    {process.env.NEXT_PUBLIC_DISABLE_MONTHLY_SUBSCRIPTION === 'true' ? (
                        <PriceInfo>
                            <Trans
                                i18nKey="pages.app.pricing.disabledMonthlySubscriptionReason"
                                components={{ strong: <strong /> }}
                            />
                        </PriceInfo>
                    ) : (
                        <PricingSwitchPayment period={period} onPeriodChange={setPeriod} />
                    )}

                    <Tariffs>
                        <TariffExclusive period={period} />
                        <TariffPremium period={period} />
                    </Tariffs>
                    <More>
                        <Button
                            href={routes.web.pricing}
                            variation={'outlined'}
                            size={{ xs: 'medium', md: 'vlarge' }}
                            icon={<IconNext />}
                        >
                            {t('blocks.pricing.common.allComparison')}
                        </Button>
                    </More>
                </Inner>
            </Container>
        </Wrapper>
    );
};
