import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    padding: 12rem 0;
    scroll-margin-top: -8rem;
`;

export const Tariffs = styled.div`
    display: flex;
    gap: 3rem;
    width: 100%;
    flex-direction: column;
    align-items: center;

    @media (min-width: 750px) {
        flex-direction: row;
        justify-content: center;
        align-items: unset;
    }
`;

export const More = styled.div`
    display: flex;
    margin-top: 6rem;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PriceInfo = styled.div`
    ${({ theme }) => css`
        display: flex;
        padding: 3rem;
        border-radius: ${theme.radius.large};
        flex-direction: column;
        max-width: 87rem;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        font-size: 2rem;
        line-height: 2.8rem;
        color: #fff;
        text-align: center;
        margin-bottom: 6rem;
        background-color: ${theme.palette.color.warning.dark};
        box-shadow: ${theme.shadow.large};

        strong {
            display: block;
            font-size: 2.6rem;
            margin-bottom: 2rem;
        }
    `};
`;
